import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LuIndianRupee } from 'react-icons/lu';
import { LuLayoutDashboard } from 'react-icons/lu';
import { GiTatteredBanner } from 'react-icons/gi';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsBell } from 'react-icons/bs';
import { RiPagesLine } from 'react-icons/ri';
import { TbBrandBlogger } from 'react-icons/tb';
import { BiEdit } from 'react-icons/bi';
import { RxUpdate } from 'react-icons/rx';
import { ImImages } from 'react-icons/im';
import { FiLogOut } from 'react-icons/fi';
import { MdEmojiEvents } from 'react-icons/md';
const Sidebar = () => {
    const urlredirect = useNavigate();
    const logOut = () => {
        localStorage.removeItem('mnb');
        urlredirect('/admin');
    }
    return (<>
        <header>
            <nav className="sidebar">
                <div className="sidebar-header">
                    <Link to="#" className="sidebar-brand">
                        Admin<span> MNB</span>
                    </Link>
                    <div className="sidebar-toggler not-active">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="sidebar-body">
                    <ul className="nav">
                        <li className="nav-item nav-category">Main</li>
                        <li className="nav-item">
                            <NavLink to="/admin/Dashboard" className="nav-link">
                                <LuLayoutDashboard className='menu-icon' />
                                <span className="link-title">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item nav-category">Information</li>
                        <li className="nav-item">
                            <NavLink to="admin/banner" className="nav-link">
                                <GiTatteredBanner className='menu-icon' />
                                <span className="link-title">Banner</span>
                            </NavLink>
                        </li>
                        <li className="nav-item nav-category">Categories</li>
                        <li className="nav-item">
                            <Link className="nav-link" data-bs-toggle="collapse" to="#mid" role="button" aria-expanded="false"
                                aria-controls="emails">
                                <AiOutlineMenu className='menu-icon' />
                                <span className="link-title">Menu</span>
                                <i className="link-arrow" data-feather="chevron-down"></i>
                            </Link>
                            <div className="collapse" id="mid">
                                <ul className="nav sub-menu">
                                    <li className="nav-item"> <NavLink to="/admin/mainmenu" className="nav-link">Main Menu</NavLink></li>
                                    <li className="nav-item"> <NavLink to="/admin/submenu" className="nav-link">Sub Menu</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item nav-category">Notification</li>
                        <li className="nav-item">
                            <Link className="nav-link" data-bs-toggle="collapse" to="#donation" role="button" aria-expanded="false"
                                aria-controls="emails">
                                <LuIndianRupee className='menu-icon' />
                                <span className="link-title">Donation</span>
                                <i className="link-arrow" data-feather="chevron-down"></i>
                            </Link>
                            <div className="collapse" id="donation">
                                <ul className="nav sub-menu">
                                    <li className="nav-item"> <NavLink to="admin/donation-list" className="nav-link">Donation List</NavLink></li>
                                    <li className="nav-item"> <NavLink to="admin/donation-grid" className="nav-link">Donation Grid</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" data-bs-toggle="collapse" to="#sms" role="button" aria-expanded="false"
                                aria-controls="emails">
                                <BsBell className='menu-icon' />
                                <span className="link-title">Massage</span>
                                <i className="link-arrow" data-feather="chevron-down"></i>
                            </Link>
                            <div className="collapse" id="sms">
                                <ul className="nav sub-menu">
                                    <li className="nav-item"> <NavLink to="admin/form" className="nav-link">Contact Form</NavLink></li>
                                    <li className="nav-item"> <NavLink to="admin/volunteer-query" className="nav-link">Volunteer Query</NavLink></li>
                                    <li className="nav-item"> <NavLink to="admin/subscriber" className="nav-link">Subscriber</NavLink></li>
                                    <li className="nav-item"> <NavLink to="admin/sendmail" className="nav-link">Send Mail</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink to="admin/event" className="nav-link">
                                <MdEmojiEvents className='menu-icon' />
                                <span className="link-title">Events</span>
                            </NavLink>
                        </li>
                        <li className="nav-item nav-category">Advance</li>
                        <li className="nav-item">
                            <NavLink to="/admin/pagelist" className="nav-link">
                                <RiPagesLine className='menu-icon' />
                                <span className="link-title">Pages</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/blog" className="nav-link">
                                <TbBrandBlogger className='menu-icon' />
                                <span className="link-title">Blog</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" data-bs-toggle="collapse" to="#page" role="button" aria-expanded="false"
                                aria-controls="emails">
                                <BiEdit className='menu-icon' />
                                <span className="link-title">Contents</span>
                                <i className="link-arrow" data-feather="chevron-down"></i>
                            </Link>
                            <div className="collapse" id="page">
                                <ul className="nav sub-menu">
                                    <li className="nav-item"><NavLink to="admin/testimonial" className="nav-link">Testimonial</NavLink></li>
                                    <li className="nav-item"><NavLink to="admin/what-we-do" className="nav-link">What we do</NavLink></li>
                                    <li className="nav-item"><NavLink to="admin/our-work" className="nav-link">Our Work</NavLink></li>
                                    <li className="nav-item"><NavLink to="admin/counters" className="nav-link">Counters</NavLink></li>
                                    <li className="nav-item"><NavLink to="admin/volunteer" className="nav-link">Volunteer</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/gallery" className="nav-link">
                                <ImImages className='menu-icon' />
                                <span className="link-title">Gallery</span>
                            </NavLink>
                        </li>
                        <li className="nav-item nav-category">Manage</li>
                        <li className="nav-item">
                            <Link className="nav-link" data-bs-toggle="collapse" to="#app" role="button" aria-expanded="false"
                                aria-controls="emails">
                                <RxUpdate className='menu-icon' />
                                <span className="link-title">Upgrate</span>
                                <i className="link-arrow" data-feather="chevron-down"></i>
                            </Link>
                            <div className="collapse" id="app">
                                <ul className="nav sub-menu">
                                    <li className="nav-item"> <NavLink to="admin/info" className="nav-link">Information</NavLink></li>
                                    <li className="nav-item"> <NavLink to="admin/socialIcon" className="nav-link">Social Icon</NavLink></li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" onClick={logOut}>
                                <FiLogOut className='menu-icon' />
                                <span className="link-title">Log Out</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    </>)
}
export default Sidebar;