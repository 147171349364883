import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
const Loader = () => {
    const [loaders,setLoader] = useState();
    
    return(<>
    <section style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100vw',
            height: '100vh',
            zIndex: '99999',
            background: '#525252a1',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: 'auto',
            color: 'white',
            transition: '300ms',
            visibility: loaders==true ? 'visible' : 'hidden',
        }}>
            <Spinner animation="grow" variant="success" />
            <div className='mt-2'> Loding...</div>
        </section>
    </>)
}
export default Loader;