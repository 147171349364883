import React, { Suspense } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './routes';
import Header from './components/public/Header';
import Footer from './components/public/Footer';
import AdminFooter from './components/admin/footer';
import AdminHeader from './components/admin/header';
import AdminSidebar from './components/admin/Siderbar';
import Login from './components/admin/Login';
import Loader from './Loader';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
let path = document.location.pathname;
let layout = 'public';
if (/^\/admin/i.test(path)) {
    layout = 'admin';
} else if (/^\/user/i.test(path)) {
    layout = 'customer';
}

const AppRoutes = (props) => {

    //  Check route
    path = document.location.pathname;
    if (/^\/admin/i.test(path)) {
        layout = 'admin';
    } else if (/^\/user/i.test(path)) {
        layout = 'customer';
    } else {
        layout = 'public';
    }

    return useRoutes(routes);
};

function App() {
    return (
        <>
            <BrowserRouter>
                {layout === 'admin'
                    ?
                    <>
                        {path === '/admin' || path === '/admin/' ? <Login /> :
                            <>
                                <div class="main-wrapper">
                                    <AdminSidebar layout={layout} />
                                    <div class="page-wrapper">
                                        <AdminHeader />
                                        <main className='page-content'>
                                            <Suspense fallback={<Loader/>}>
                                                <AppRoutes layout={layout} />
                                            </Suspense>
                                        </main>
                                        <AdminFooter layout={layout} />
                                    </div>
                                </div>
                            </>
                        }
                    </>
                    :
                    <>{path === '/login' || path === '/login/' ?
                        <Login />
                        :
                        <>
                            <Header layout={layout} />
                            <main>
                                <Suspense fallback={<Loader/>}>
                                    <AppRoutes layout={layout} />
                                </Suspense>
                            </main>
                            <Footer layout={layout} />
                        </>
                    }</>

                }
            </BrowserRouter>
        </>
    );
}

export default App;