import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup';
import { MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";
import { ImPointUp } from 'react-icons/im';
import { AiOutlineUser } from 'react-icons/ai';
import './css/main.css';
import './css/animate.css';
let login = false;
function Header() {
  const [smShow, setSmShow] = useState(false);
  let [data, setData] = useState({ social: [{ icon: '', url: '', status: '' }], info: { phone: '' }, header: [{ sno: '1', name: 'ss' }] });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/header')
      .then(response => {
        setData(response.data);
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });
    if (!sessionStorage.getItem('user')) {
      login = false;
    } else {
      login = true;
    }
  }, [data]);
  function Loading(placeholders) {
    placeholders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
    return (<>
      {placeholders.map((i) => (
        <p className="placeholder-wave mb-0" key={i}>
          <span className="placeholder col-12 bg-secondary"></span>
        </p>
      ))}
    </>)
  }
  const toast_alert = (sms, status) => {
    if (status == 0) {
      toast.success(sms, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(sms, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  const phonenumber = (e) => {
    e.target.value = e.target.value.slice(0,10);
    e.target.value = e.target.value.replace(/[^0-9 ]/g, '');
    if(e.target.value[0]!=9 && e.target.value[0]!=8 && e.target.value[0]!=7 && e.target.value[0]!=6){ e.target.value=''; }
  }
  const Otpvrify = (e) =>{
    e.target.value = e.target.value.slice(0,6);
    e.target.value = e.target.value.replace(/[^0-9 ]/g, '');
  }
  const sendOtp = (o) => {
    o.preventDefault();
    var phone = o.target.phone.value;
    var newotp = (Math.random() * 1000000).toFixed();
    // use meggage api

    if (newotp != '') {
      let storedata = phone + ',' + newotp;
      const encryptdata = CryptoJS.AES.encrypt(storedata, process.env.REACT_APP_ECCRIPTION_KEY).toString();
      sessionStorage.setItem('log', encryptdata);
      if (sessionStorage.getItem('log') != null) {
        document.getElementById("steplog1").style.display = 'none';
        document.getElementById("steplog2").style.display = 'block';
      }
    }
  }
  const otpvrification = (v) => {
    v.preventDefault();
    if (sessionStorage.getItem('log') != null) {
      var realstring = CryptoJS.AES.decrypt(sessionStorage.getItem('log'), process.env.REACT_APP_ECCRIPTION_KEY).toString(CryptoJS.enc.Utf8);
      var stored_data = realstring.split(',');
      console.log(stored_data);
      if (stored_data[1] == v.target.otp.value) {
        sessionStorage.setItem('user', CryptoJS.AES.encrypt(stored_data[0], process.env.REACT_APP_ECCRIPTION_KEY).toString());
        if (sessionStorage.getItem('user') != null) {
          sessionStorage.removeItem('log');
          fetch(process.env.REACT_APP_API_URL +'/user', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                phone: stored_data[0]
            })
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 'success') {
                  setSmShow(false);
                  console.log(stored_data[1]);
                } else {
                    toast_alert('Please try agine after sometime', 1);
                }
            }).catch((error) => {
                toast_alert('something went wrong', 1);
            });
        }
      } else {
        toast_alert('Invalid OTP...!!', 1);
      }
    } else {
      document.getElementById("steplog1").style.display = 'block';
      document.getElementById("steplog2").style.display = 'none';
    }
  }
  const menu = () => {
    document.querySelector(".navbar-collapse").className = "navbar-collapse collapse show";
  }

  return (<>
    <header>
      <div className="body-overlay" id="body-overlay"></div>
      <div className="search-popup" id="search-popup">
        <form action="" className="search-form">
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Search....." />
          </div>
          <button className="close-btn border-none"><i className="fas fa-times"></i></button>
        </form>
      </div>
      <div className="header-style-01">
        <div className="topbar-area">
          <div className="container-">
            <div className="row">
              <div className="col-lg-12">
                <div className="topbar-inner">
                  <div className="left-contnet">
                    <ul className="info-items">
                      <li><Link to={"tel:" + data?.info[0]?.phone}><i className="fa fa-phone" style={{ transform: "rotate(90deg)" }}></i>{data?.info[0]?.phone}</Link></li>
                      <li>
                        <Link to={"mailto: " + data?.info[0]?.email} >
                          <i className="far fa-envelope-open"></i><span className="__cf_email__" data-cfemail="254c4b434a65405d44485549400b464a48">
                            {data?.info[0]?.email}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="right-contnet">
                    <div className="social-link">
                      <ul>
                        {
                          data && data.social.map((icon) => {
                            if (icon.status === 1) {
                              return (<>
                                <li><Link to={icon.url}><div className="Container" dangerouslySetInnerHTML={{__html: icon.name}}></div></Link></li>
                              </>)
                            }
                          })
                        }
                      </ul>
                    </div>
                    <div className="volunteer-right">
                      <ul className="info-items-02">
                        {login == true ? <li><NavLink to="/myaccount"><AiOutlineUser/> My Account</NavLink></li> : <li onClick={() => setSmShow(true)}><Link><AiOutlineUser/> Login</Link></li>}
                        <li className="volunteer"><Link to=""><i><ImPointUp /></i>Want to work with us ?</Link></li>
                        <li className="volunteer"><NavLink to="volunteer">Become a Volunteer</NavLink></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-area navbar-expand-lg has-topbar nav-style-02">
          <div className="container-fluid nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo-wrapper">
                <Link to="/" className="logo">
                  <img src={process.env.REACT_APP_BASE_URL + "/main/files/dynamic/" + data?.info[0]?.header_logo} />
                </Link>
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#bizcoxx_main_menu" aria-expanded="false" aria-label="Toggle navigation" onClick={menu}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="bizcoxx_main_menu">
              <ul className="navbar-nav">
                {
                  data && data.header.map((item, i) => {
                    let subMenu = []
                    if (item.category === 0) {
                      for (let j = 0; j < data.header.length; j++) {
                        if (item.sno === data.header[j].category) {
                          subMenu.push(data.header[j])
                        }
                      }
                      const sizeOfSubmenu = subMenu.length
                    }

                    if (
                      item.category <= 0
                    ) {
                      return (
                        <>
                          {item.status === 0 ? (<></>) : (
                            <li key={item.sno} className={subMenu.length > 0 ? 'menu-item-has-children' : ''}>
                              {subMenu.length > 0 ? (<>{item.name}</>) : (<NavLink to={`/${item.url}`} >{item.name}</NavLink>)}

                              {
                                subMenu.length > 0 ? (

                                  <ul className="sub-menu">
                                    {
                                      subMenu.map((subItem, i) => {
                                        return (
                                          subItem.status === 0 ? (<></>) : (<li key={i}><NavLink to={`/${subItem.url}`}>{subItem.name}</NavLink></li>)

                                        )
                                      })
                                    }
                                  </ul>
                                ) : (
                                  <></>
                                )
                              }


                            </li>
                          )

                          }
                        </>


                      )
                    }
                    else {
                      return (
                        <></>
                      )
                    }

                  })
                }
              </ul>
            </div>
            <div className="nav-right-content">
              <ul>
                <li>
                  <Link to="/donate">
                    <div className="info-bar-item">
                      <div className="icon">
                        <i className="far fa-heart"></i>
                      </div>
                      <div className="content">
                        <span className="title">
                          SUPPORT
                        </span>
                        <p className="details">  DONATE</p>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm"> Login </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="steplog1" onSubmit={sendOtp}>
          <InputGroup className="mb-3">
            <MDBInput label='Phone' id='phone' name="phone" autoComplete='off' type='text' className='inputfld'onInput={phonenumber} required />
          </InputGroup>
          <MDBBtn className='me-1 donate-continue'> Login </MDBBtn>
        </Form>
        <Form id="steplog2" onSubmit={otpvrification}>
          <InputGroup className="mb-3">
            <MDBInput label='OTP' id='otp' name="otp" autoComplete='off' type='text' className='inputfld'onInput={Otpvrify} required />
          </InputGroup>
          <MDBBtn className='me-1 donate-continue'> Verify </MDBBtn>
        </Form>
      </Modal.Body>
    </Modal>
  </>);
}

export default Header;