import React from 'react';
//	Public 
const Home = React.lazy(() => import( /*webpackChunkName: 'index'*/ './components/public/Home'));
const Aboutus = React.lazy(() => import( /*webpackChunkName: 'about'*/ './components/public/About'));
const Ourwork = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Ourwork'));
const Eventsdetails = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Eventsdetails'));
const Events = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Events'));
const Contactus = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Contactus'));
const Blog = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Blog'));
const Blog_Details = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Blog_details'));
const Volunteer = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Volunteer'));
const DonationPage = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/DonationPage'));
const Donationuser = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/DonateUser_dt'));
const Myaccount = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Myaccount'));
const Thanks = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Thanks'));
const Event_booking = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Event_booking'));
const Error404 = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/public/Error404'));
// Admin
const Login = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Login'));
const Admin404 = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Admin404'));
const Dashboard = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Dashboard'));
const Banner = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Banner'));
const Form = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Form'));
const EditBanner = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditBanner'));
const Info = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Info'));
const SocialIcon = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Social_icon'));
const Sendmail = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Sendmail'));
const Subscriber = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Subscriber'));
const Mainmenu = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Mainmenu'));
const Submenu = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Submenu'));
const BlogList = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/BlogList'));
const Addblog = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/AddBlog'));
const EditBlog = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditBlog'));
const NewPage = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/NewPage'));
const Pagelist = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Pagelist'));
const AddTestimonial = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/AddTestimonial'));
const Testimonial = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Testimonial'));
const EdiMainmenu = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EdiMainmenu'));
const Edisubmenu = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditSubmenu'));
const EditTestimonial = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditTestimonial'));
const EditPage = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditPage'));
const DonatinList = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Donation_List'));
const DonationGrid = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Donation_Grid'));
const Whatwedo_List = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/What_we_do_List'));
const AddWhatwedo = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Add_What_we_do_List'));
const Edit_What_we_do = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Edit_What_we_do'));
const CountDwon = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/CountDwon'));
const OurWorkList = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/OurWorkList'));
const AddOurWorkList = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Add_our_work'));
const EditOurWork = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Edit_OurWork'));
const AddSocialIcon = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/AddSocialIcon'));
const EditIcon = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditIcon'));
const Image = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Images'));
const Events_list = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EventsList'));
const New_Event = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/New_Event'));
const EditEvent = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/EditEvent'));
const VolunteerList = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/VolunteerList'));
const Booking_details = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Booking_details'));
const Add_volunnteer = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Add_volunnteer'));
const Update_volunnteer = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Update_volunnteer'));
const Vender_query = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Vender_query'));
const Vender_query_details = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Voluntee_details'));
const Form_query = React.lazy(() => import( /*webpackChunkName: 'error'*/ './components/admin/Form_query'));
const path = document.location.pathname;
let layout = 'public';
if (/^\/admin/i.test(path)) {
	layout = 'admin';
}

const Routes = [
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/about-us',
		element: <Aboutus />
	},
	{
		path: '/our-work',
		element: <Ourwork />
	},
	{
		path: '/events',
		element: <Events />
	},
	{
		path: '/events/:id',
		element: <Eventsdetails />
	},
	{
		path: '/event/booking/:id',
		element: <Event_booking />
	},
	{
		path: '/blog',
		element: <Blog />
	},
	{
		path: '/blog/:id',
		element: <Blog_Details />
	},
	{
		path: '/contact-us',
		element: <Contactus />
	},
	{
		path: '/volunteer',
		element: <Volunteer />
	},
	{
		path: '/donate',
		element: <DonationPage />
	},
	{
		path: '/donate/details/:id',
		element: <Donationuser />
	},
	{
		path: '/login',
		element: <Login />
	},
	{
		path: '/donation/thanks',
		element: <Thanks />
	},
	{
		path: '/myaccount',
		element: <Myaccount />
	},
	
	{
		path: '*',
		element: <Error404 />
	},


	//	Admin Paths
	{
		path: '/admin',
		children: [
			{
				path: 'dashboard',
				element: <Dashboard />
			},
			{
				path: '/admin/banner',
				element: <Banner />
			},
			{
				path: '/admin/form',
				element: <Form />
			},
			{
				path: '/admin/banner/edit/:sno',
				element: <EditBanner />
			},
			{
				path: '/admin/Info',
				element: <Info />
			},
			{
				path: '/admin/socialIcon',
				element: <SocialIcon />
			},
			{
				path: '/admin/subscriber',
				element: <Subscriber />
			},
			{
				path: '/admin/sendmail',
				element: <Sendmail />
			},
			{
				path: '/admin/mainmenu',
				element: <Mainmenu />
			},
			{
				path: '/admin/submenu',
				element: <Submenu />
			},
			{
				path: '/admin/mainmenu/:id',
				element: <EdiMainmenu />
			},

			{
				path: '/admin/submenu/:id',
				element: <Edisubmenu />
			},
			{
				path: '/admin/blog',
				element: <BlogList />
			},
			{
				path: '/admin/blog/add',
				element: <Addblog />
			},
			{
				path: '/admin/blog/edit/:id',
				element: <EditBlog />
			},
			{
				path: '/admin/newpage',
				element: <NewPage />
			},
			{
				path: '/admin/pagelist',
				element: <Pagelist />
			},
			{
				path: 'pagelist/page-edit/:sn',
				element: <EditPage />
			},
			{
				path: '/admin/testimonial',
				element: <Testimonial />
			},
			{
				path: '/admin/addtestimonial',
				element: <AddTestimonial />
			},
			{
				path: '/admin/testimonial/edit/:id',
				element: <EditTestimonial />
			},
			{
				path: '/admin/donation-list',
				element: <DonatinList />
			},
			{
				path: '/admin/donation-grid',
				element: <DonationGrid />
			},
			{
				path: '/admin/what-we-do',
				element: <Whatwedo_List />
			},
			{
				path: '/admin/what-we-do/add',
				element: <AddWhatwedo />
			},
			{
				path: '/admin/what-we-do/edit/:id',
				element: <Edit_What_we_do />
			},
			{
				path: '/admin/counters',
				element: <CountDwon />
			},
			{
				path: '/admin/our-work',
				element: <OurWorkList />
			},
			{
				path: '/admin/our-work/add',
				element: <AddOurWorkList />
			},
			{
				path: '/admin/our-work/edit/:id',
				element: <EditOurWork />
			},
			{
				path: '/admin/socialIcon/add',
				element: <AddSocialIcon />
			},
			{
				path: '/admin/socialIcon/edit/:id',
				element: <EditIcon />
			},
			{
				path: '/admin/gallery',
				element: <Image/>
			},
			{
				path: '/admin/event',
				element: <Events_list/>
			},
			{
				path: '/admin/event/add',
				element: <New_Event/>
			},
			{
				path: '/admin/event/edit/:id',
				element: <EditEvent/>
			},
			{
				path: '/admin/event/booking/:id',
				element: <Booking_details/>
			},
			{
				path: '/admin/volunteer',
				element: <VolunteerList/>
			},
			{
				path: '/admin/volunteer/add',
				element: <Add_volunnteer/>
			},
			{
				path: '/admin/volunteer/:id',
				element: <Update_volunnteer/>
			},
			{
				path: '/admin/volunteer-query',
				element: <Vender_query/>
			},
			{
				path: '/admin/volunteer-query/:id',
				element: <Vender_query_details/>
			},
			{
				path: '/admin/form/:id',
				element: <Form_query/>
			},
			{
				path: '*',
				element: <Admin404 />
			},
		]
	}
];

export default Routes;