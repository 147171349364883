import React, { useState, useEffect } from 'react';
import { ToastContainer} from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './css/core.css';
import './css/admin2.css';
import axios from 'axios';
const Header = () => {
  const urlredirect = useNavigate();
  let [auth, token] = useState([]);
  useEffect(() => async () => {
    var tokens = localStorage.getItem('mnb');
    await axios.get(process.env.REACT_APP_API_URL + '/authenticate?token=' + tokens)
      .then(function (response) {
        token(response.data);
      }).catch(function (err) {
        console.log(err);
      })
  }, [auth]);
  if (auth.status != 'success') {
    urlredirect('/admin');
    urlredirect(0);
  }
  return (<>
    <nav className="navbar">
      <Link to="#" className="sidebar-toggler">
        <i data-feather="menu"></i>
      </Link>
      <div className="navbar-content">
        <form className="search-form">
          <div className="input-group">
            <div className="input-group-text">
              <i data-feather="search"></i>
            </div>
            <input type="text" className="form-control" id="navbarForm" placeholder="Search here..." />
          </div>
        </form>
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to="#" id="appsDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i data-feather="grid"></i>
            </Link>
            <div className="dropdown-menu p-0" aria-labelledby="appsDropdown">
              <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                <p className="mb-0 fw-bold">Web Apps</p>
                <Link to="javascript:;" className="text-muted">Edit</Link>
              </div>
              <div className="row g-0 p-1">
                <div className="col-3 text-center">
                  <Link to="https://www.nobleui.com/html/template/demo1/pages/apps/chat.html"
                    className="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                      data-feather="message-square" className="icon-lg mb-1"></i>
                    <p className="tx-12">Chat</p>
                  </Link>
                </div>
                <div className="col-3 text-center">
                  <Link to="https://www.nobleui.com/html/template/demo1/pages/apps/calendar.html"
                    className="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                      data-feather="calendar" className="icon-lg mb-1"></i>
                    <p className="tx-12">Calendar</p>
                  </Link>
                </div>
                <div className="col-3 text-center">
                  <Link to="https://www.nobleui.com/html/template/demo1/pages/email/inbox.html"
                    className="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                      data-feather="mail" className="icon-lg mb-1"></i>
                    <p className="tx-12">Email</p>
                  </Link>
                </div>
                <div className="col-3 text-center">
                  <Link to="profile.html"
                    className="dropdown-item d-flex flex-column align-items-center justify-content-center wd-70 ht-70"><i
                      data-feather="instagram" className="icon-lg mb-1"></i>
                    <p className="tx-12">Profile</p>
                  </Link>
                </div>
              </div>
              <div className="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                <Link to="javascript:;">View all</Link>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to="#" id="notificationDropdown" role="button"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i data-feather="bell"></i>
              <div className="indicator">
                <div className="circle"></div>
              </div>
            </Link>
            <div className="dropdown-menu p-0" aria-labelledby="notificationDropdown">
              <div className="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
                <p>6 New Notifications</p>
                <Link to="javascript:;" className="text-muted">Clear all</Link>
              </div>
              <div className="p-1">
                <Link to="javascript:;" className="dropdown-item d-flex align-items-center py-2">
                  <div
                    className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="gift"></i>
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>New Order Recieved</p>
                    <p className="tx-12 text-muted">30 min ago</p>
                  </div>
                </Link>
                <Link to="javascript:;" className="dropdown-item d-flex align-items-center py-2">
                  <div
                    className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="alert-circle"></i>
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>Server Limit Reached!</p>
                    <p className="tx-12 text-muted">1 hrs ago</p>
                  </div>
                </Link>
                <Link to="javascript:;" className="dropdown-item d-flex align-items-center py-2">
                  <div
                    className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <img className="wd-30 ht-30 rounded-circle" src="main/images/faces/face6.jpg" alt="userr" />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>New customer registered</p>
                    <p className="tx-12 text-muted">2 sec ago</p>
                  </div>
                </Link>
                <Link to="javascript:;" className="dropdown-item d-flex align-items-center py-2">
                  <div
                    className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="layers"></i>
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>Apps are ready for update</p>
                    <p className="tx-12 text-muted">5 hrs ago</p>
                  </div>
                </Link>
                <Link to="javascript:;" className="dropdown-item d-flex align-items-center py-2">
                  <div
                    className="wd-30 ht-30 d-flex align-items-center justify-content-center bg-primary rounded-circle me-3">
                    <i className="icon-sm text-white" data-feather="download"></i>
                  </div>
                  <div className="flex-grow-1 me-2">
                    <p>Download completed</p>
                    <p className="tx-12 text-muted">6 hrs ago</p>
                  </div>
                </Link>
              </div>
              <div className="px-3 py-2 d-flex align-items-center justify-content-center border-top">
                <Link to="javascript:;">View all</Link>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to="#" id="profileDropdown" role="button" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <img className="wd-30 ht-30 rounded-circle" src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" alt="profile" />
            </Link>
            <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
              <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                <div className="mb-3">
                  <img className="wd-80 ht-80 rounded-circle" src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg" alt="" />
                </div>
                <div className="text-center">
                  <p className="tx-16 fw-bolder">Amiah Burton</p>
                  <p className="tx-12 text-muted">amiahburton@gmail.com</p>
                </div>
              </div>
              <ul className="list-unstyled p-1">
                <li className="dropdown-item py-2">
                  <Link to="profile.html" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="user"></i>
                    <span>Profile</span>
                  </Link>
                </li>
                <li className="dropdown-item py-2">
                  <Link to="javascript:;" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="edit"></i>
                    <span>Edit Profile</span>
                  </Link>
                </li>
                <li className="dropdown-item py-2">
                  <Link to="javascript:;" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="repeat"></i>
                    <span>Switch User</span>
                  </Link>
                </li>
                <li className="dropdown-item py-2">
                  <Link to="javascript:;" className="text-body ms-0">
                    <i className="me-2 icon-md" data-feather="log-out"></i>
                    <span>Log Out</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <ToastContainer />
  </>)
}
export default Header;